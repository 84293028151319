import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Music Production Podcast | Striking Kooks with Jimmy Ether"
        ogDescription="Striking Kooks with Jimmy Ether is a music production podcast. We interview musical artists, producers, audio engineers, mastering engineers, and related professionals."
        url="https://strikingkooks.com"
      />
      <PageWrapper color="umber">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h1>Music Production Podcast</h1>
              <h2>Striking Kooks with Jimmy Ether</h2>
              <p>
                Striking Kooks with Jimmy Ether is a music production podcast. We interview musical artists, producers, audio engineers, mastering engineers, and related professionals.
              </p>
              <p>
                We'll be launching in mid-Novemeber of 2022 on Stitcher, Apple Podcasts, Spotify, and whereever you listen to podcasts.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Get in touch!</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
